<template>
  <main>
    <div class="cards-container" :class="layout">
      <Card
        size="full"
        chartOrGrid="grid-layout"
        title="Services"
        flexDirection="column">
        <template v-slot:content>
          <AgGridVue
            style="width: 100%; min-height: 700px"
            class="ag-theme-alpine"
            :columnDefs="this.columns"
            :rowData="this.data"
            :rowSelection="rowSelection"
            :pagination="true"
            :paginationAutoPageSize="true">
          </AgGridVue
        ></template>
        <!-- <div class="table-controllers">
          <button><Download />Download</button>
        </div> -->
      </Card>
    </div>
  </main>
</template>

<script>
  import store from "@/store";
  import Card from "@/components/Card";
  import { AgGridVue } from "ag-grid-vue3";
  import { screenDetector } from "@/helpers/screenDetector";

  export default {
    data() {
      return {
        showChart: true,
        layout: "desktop",
        loading: false,
        rowSelection: "single",
        data: [],
        columns: [
          { field: "date", resizable: true, sortable: true, filter: true },
          {
            field: "startTime",
            resizable: true,
            sortable: true,
            filter: true,
          },
          {
            field: "endTime",
            resizable: true,
            sortable: true,
            filter: true,
          },
          {
            field: "orderingChannels",
            resizable: true,
            sortable: true,
            filter: true,
          },
        ],
        pageSize: 10,
      };
    },

    components: {
      Card,
      AgGridVue,
    },

    methods: {
      updateIfDesktopLayoutState() {
        this.layout = screenDetector();
      },

      updateData() {
        window.log.info("[⚙️] Downloading service data..");

        // TODO: Switch based on vendor, venue etc
        store.state.apiPrivate.client.endpoints.services
          .getServices()
          .then((response) => {
            if (response.status == 200) {
              return response.data;
            }

            return Promise.reject("Could not download services.");
          })
          .then((payload) => {
            this.data = payload.data;
            //this.pageSize = parseInt(payload.meta["page-size"]);

            window.log.info(payload);
          });
      },
    },

    created() {
      this.rowSelection = "multiple";
    },

    mounted() {
      this.updateIfDesktopLayoutState();
      window.addEventListener("resize", this.updateIfDesktopLayoutState);

      this.updateData();
    },

    unmounted() {
      window.removeEventListener("resize", this.updateIfDesktopLayoutState);
    },
  };
</script>

<style lang="scss">
  .revenue-payout-summary {
    display: flex;
    flex-direction: row;
    &__data {
      width: 50%;
      max-width: 800px;
      min-width: 300px;
      .xl-desktop &,
      .desktop & {
        margin-left: 4rem;
      }
    }
    &.tablet,
    &.mobile {
      flex-direction: column;
    }
    &__title,
    &__sub-title {
      color: #aaa;
      margin: 0;
    }
    &__value {
      align-items: end;
      height: 50px;
      p {
        font-size: 2rem;
        margin: 0;
      }
      span.percentage {
        margin-left: 1rem;
        line-height: 2rem;
      }
    }
    &__sub-title {
      font-size: 0.7rem !important;
      margin-bottom: 0.1rem !important;
    }
    &__sub-value {
      align-items: center;
      p {
        font-size: 1.6rem;
        margin: 0;
      }
    }
    &__revenue-content {
      align-items: flex-end;
      & div:first-child {
        .xl-desktop &,
        .desktop {
          margin-right: 3rem;
        }
      }
    }
  }

  span.growth-percentage-chip {
    background: $col_beta;
    border-radius: $card_radius;
    padding: 0.5rem 1rem;
    color: #fff;
    margin: 0 1rem;
    &.declined {
      background: $col_alpha;
    }
  }
</style>
